import classNames from 'classnames'
import React from 'react'
import Media from 'react-media'
import Post from 'blog/components/Post'
import Container from 'components/Container'
import Link from 'components/Link'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import constants from 'styles/constants.module.scss'
import styles from './Posts2.module.scss'

type Props = {
  data: unknown
} & Component

export default function Posts2({ className, data }: Props) {
  const [postFeatured, ...posts] = data

  const postsSecondary = posts.slice(0, 3)
  const postsTertiary = posts.slice(3)

  const breakpoint = constants.DESKTOP

  return (
    <div className={classNames('Posts2', styles.this, className)}>
      <div className={styles.top}>
        <figure
          style={{ backgroundImage: `url(${postFeatured.node.frontmatter.image.publicURL})` }}
          className={styles['post-featured']}>
          {/* <Image src={postFeatured.node.frontmatter.image} /> */}
          {/*
          <Text element="figcaption" className={styles['post-featured--caption']}>
            <Text element="h3" weight="medium" className={styles['post-featured--title']}>
              {postFeatured.node.frontmatter.title}
            </Text>
            <Text element="p">
              {postFeatured.node.excerpt}
            </Text>
            <Link to={postFeatured.node.fields.slug} alt>Read on</Link>
          </Text>
          */}
        </figure>
        <Media query={{ minWidth: breakpoint + 1 }}>
          <div className={styles['posts-secondary']}>
            {postsSecondary.map((item) => {
              const { node: post } = item

              return (
                <div key={post.id} className={styles['posts-secondary--item']}>
                  <Text
                    element="h3"
                    weight="medium"
                    className={styles['posts-secondary--item--title']}>
                    {post.frontmatter.title}
                  </Text>
                  <Link to={post.fields.slug} alt>
                    Read on
                  </Link>
                </div>
              )
            })}
          </div>
        </Media>
      </div>
      <Container size="xxlarge">
        <div className={styles['posts-tertiary']}>
          <Media query={{ minWidth: breakpoint }}>
            <>
              {postsSecondary.map((item) => {
                const { node: post } = item

                return (
                  <Post
                    to={post.fields.slug}
                    title={post.frontmatter.title}
                    image={post.frontmatter.image}
                    key={post.fields.slug}
                    className={styles['posts-tertiary--item']}
                  />
                )
              })}
            </>
          </Media>
          {postsTertiary.map((item) => {
            const { node: post } = item

            return (
              <Post
                to={post.fields.slug}
                title={post.frontmatter.title}
                image={post.frontmatter.image}
                key={post.fields.slug}
                className={styles['posts-tertiary--item']}
              />
            )
          })}
        </div>
      </Container>
    </div>
  )
}
